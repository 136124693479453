<script setup lang="ts">
import type { CategoryModel } from '@/types/category';
import type { FilterModel, FilterValueModel } from '@/types/filters';

defineOptions({
  name: 'CategoryBottomNavigation',
});

const props = defineProps({
  category: {
    type: Object as PropType<CategoryModel>,
    required: true,
  },
  filterList: {
    type: Array as PropType<FilterModel[]>,
    default: () => [],
  },
});

const { $mopConfig, $mopI18n } = useNuxtApp();
const { addFilterValue, removeAllFilters } = useMopProducts();

const navigationItemsRef = computed(() => {
  const items: {
    filterValue: FilterValueModel;
    filter: FilterModel;
    id: string;
    url: string;
    name: string;
  }[] = [];
  const categoryName: string = props.category.getName($mopConfig);
  if (!categoryName) {
    return [];
  }
  const url = props.category.getUrl();

  props.filterList.forEach((filter) => {
    const parameterName: string = filter.getSlug();
    filter.getValues().forEach((value) => {
      items.push({
        filterValue: value,
        filter,
        id: value.getKey(),
        url: `${$mopI18n.localePath(url)}?${parameterName}=${value.getValue()}`,
        name: $mopI18n.t('common.category.heading_with_color', [categoryName, value.getName()]),
      });
    });
  });

  return items;
});

async function addColorFilter(filter: FilterModel, filterValue: FilterValueModel) {
  await removeAllFilters();
  await addFilterValue(filter, filterValue);
}
</script>

<template>
  <div class="category-bottom-navigation">
    <UiScrollNavigation :navigation-items="navigationItemsRef">
      <template #list>
        <a
          v-for="navigationItem in navigationItemsRef"
          :key="navigationItem.id"
          :href="navigationItem.url"
          class="ui-mop-scroll-navigation__list-link toggle-opacity"
          @click.stop.prevent="addColorFilter(navigationItem.filter, navigationItem.filterValue)"
        >
          {{ navigationItem.name }}
        </a>
      </template>
    </UiScrollNavigation>
  </div>
</template>

<style scoped lang="scss">
.category-bottom-navigation {
  padding: $space5 $global-padding;

  &:deep(.ui-mop-scroll-navigation) {
    margin: 0;
  }
}
</style>
